import firebase from 'firebase/compat/app'

import { RELATED_PLATFORM_SITE } from 'config/app-version'
import { splitChunks } from 'utils/arrays'
import { Shop } from 'types'

import { COLLECTION_SHOPS, db } from './firebase'

async function getShopsByKeys(shopKeys: string[]): Promise<Shop[]> {
  const shopsArray: Shop[] = []
  const chunkedArray = splitChunks(shopKeys, 10)

  for (const chunk of chunkedArray) {
    const query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> =
      await db
        .collection(COLLECTION_SHOPS)
        .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
        .where('isActive', '==', true)
        .where('relatedPlatformSite', '==', RELATED_PLATFORM_SITE)
        .get({ source: 'server' })

    const shops = query.docs.map(
      (doc: firebase.firestore.DocumentData): Shop => {
        const item = doc.data() as Shop
        const shop = {
          ...item,
          key: doc.id,
        }

        return shop
      },
    )

    shopsArray.push(...shops)
  }

  return shopsArray
}

export const ApiServiceShops = {
  getShopsByKeys,
}
