import { GameDiceRolling } from 'types'

import { cloudFunctions } from './firebase'

async function buyRound({
  price,
  shopKey,
  shopName,
}: {
  price: number
  shopKey: string
  shopName: string
}): Promise<GameDiceRolling> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-gameDiceRolling-buyRound',
  )

  // Call function
  const result = await cloudFunction({
    price,
    shopKey,
    shopName,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

async function redeemPrize(transactionKey: string): Promise<void> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-gameDiceRolling-redeemPrize',
  )

  // Call function
  const result = await cloudFunction({
    transactionKey,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }
}

export const ApiServiceGameDiceRolling = {
  buyRound,
  redeemPrize,
}
