import { hexToRgb } from '@material-ui/core'

export const colorBlack = '#202020'
export const colorBlackBackground = '#f3dd0b'
export const colorBlackish = '#1C2026'
export const colorError = '#D93025'
export const colorGallery = '#EEEEEE'
export const colorGrayDark = '#2f3bs2'
export const colorHippieGreen = '#4a854a'
export const colorFruitSalad = '#5da55d'
export const colorGrayLight = '#f9f9ff'
export const colorGrayMedium = '#70889e'
export const colorMenuText = '#5e758c'
export const colorMenuTextBackground = '#f3dd0b'
export const colorMenuTextSelected = '#FFFFFF'
export const colorMenuTextSelectedTextBackground = '#191b21'
export const colorMirage = '#192130'
export const colorMischka = '#e0e0e5'
export const colorParsley = '#7FB800'
export const colorRiverBed = '#404854'
export const colorTextPrimary = '#181818'
export const colorWhite = '#FFFFFF'
export const colorWhiteBackground = '#f9f9f9'
export const colorWebOrange = '#ffa500'

export const colorRipeLemon = '#f3dd0b'
const colorPumice = '#bac1ba'
export const colorCrimson = '#a50e1e'
export const colorBulgarianRose = '#3e050b'

export const colorPrimaryDark = '#CEA13D'
export const colorPrimaryLight = '#FFE55E'
export const colorPrimaryMain = '#FFE55E'

export const colorSecondaryDark = '#4DC9F5'
export const colorSecondaryLight = '#3D6FB6'
export const colorSecondaryMain = '#131335'

// #FFE55E
// #CEA13D
// #4DC9F5
// #3D6FB6
// #131335

const colorPalette = {
  background: {
    dark: colorBlackish,
    light: colorWhite,
  },
  error: {
    light: { main: colorError },
  },
  primary: {
    light: {
      dark: colorPrimaryDark,
      light: colorPrimaryLight,
      main: colorPrimaryMain,
    },
  },
  secondary: {
    dark: { main: colorSecondaryDark },
    light: {
      dark: colorSecondaryLight,
      main: colorSecondaryMain,
    },
  },
}

export const themeColors = {
  divider: 'rgb(242, 244, 247)',
  gameOptionSelector: {
    editButton: {
      dark: colorPalette.secondary.dark.main,
      light: colorPalette.secondary.light.main,
    },
  },
  games: {
    dices: {
      diceColor: colorPrimaryMain,
    },
  },
  mobileToolbar: colorPalette.secondary.light.main,
  palette: colorPalette,
  screens: {
    login: {
      background: `linear-gradient(0deg, ${hexToRgb(colorSecondaryMain)} 0%, ${hexToRgb(colorSecondaryLight)} 60%, ${hexToRgb(colorPrimaryDark)} 90%, ${hexToRgb(colorPrimaryMain)} 100%)`,
    },
  },
  sideBarMenu: {
    divider: colorPumice,
    itemCategoryText: colorWhite,
    itemText: colorPumice,
    menu: colorPalette.secondary.light.main,
    menuActiveItem: colorPalette.secondary.light.dark,
    menuHoverItem: colorPalette.secondary.light.dark,
    shadow: 'rgba(0,0,0,0.6)',
  },

  tab: {
    hover: {
      dark: 'rgba(0,0,0,0.5)',
      light: colorMischka,
    },
  },
  table: {
    cell: {
      even: {
        dark: 'transparent',
        hover: {
          dark: 'rgba(204,204,204,0.4)',
          light: 'rgba(204,204,204,0.4)',
        },
        light: 'transparent',
      },
      odd: {
        dark: 'transparent',
        hover: {
          dark: 'rgba(204,204,204,0.4)',
          light: 'rgba(204,204,204,0.4)',
        },
        light: 'transparent',
      },
    },
    divider: 'rgb(242, 244, 247)',
  },
}
