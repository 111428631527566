import firebase from 'firebase/compat/app'

import { GameType } from 'types'

import { COLLECTION_GAME_TYPES, db } from './firebase'

async function getActiveGameTypes(): Promise<GameType[]> {
  const querySnapshot = await db
    .collection(COLLECTION_GAME_TYPES)
    .where('isActive', '==', true)
    .get({ source: 'server' })
  const queryDocuments = querySnapshot.docs

  const data: GameType[] = []
  queryDocuments.forEach((doc: firebase.firestore.DocumentData) => {
    const item = doc.data() as GameType

    if (!item.isTestMode) {
      data.push({
        ...item,
        key: doc.id,
      })
    }
  })

  return data
}

export const ApiServiceGameTypes = {
  getActiveGameTypes,
}
