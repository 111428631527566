import { cloudFunctions } from './firebase'

async function processCoupon(couponCode: string) {
  const cloudFunction = cloudFunctions.httpsCallable('client-user-cashInCoupon')

  // Call function
  const result = await cloudFunction({
    couponCode,
  })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }
}

export const ApiServiceShopsCoupons = {
  processCoupon,
}
