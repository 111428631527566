import { themeColors } from 'common/colors'
import { fonts } from 'common/fonts'

export function calcRem(...pxValues: number[]): string {
  return pxValues.map((pxValue) => `${pxValue / 16}rem`).join(' ')
}

/**
 * @deprecated
 */
export function pxToRem(value: number): string {
  return `${value / 16}rem`
}

export function titleStyles(): { fontFamily: string } {
  return {
    fontFamily: fonts.title,
  }
}

export function tableStyles(useDarkMode: boolean): {
  oddCell: {
    [key: string]: any
    backgroundColor: string
    padding: number
  }
  evenCell: {
    [key: string]: any
    backgroundColor: string
    padding: number
  }
} {
  return {
    evenCell: {
      '&:hover': {
        backgroundColor: useDarkMode
          ? themeColors.table.cell.even.hover.dark
          : themeColors.table.cell.even.hover.light,
      },
      backgroundColor: useDarkMode
        ? themeColors.table.cell.even.dark
        : themeColors.table.cell.even.light,
      padding: 0,
    },
    oddCell: {
      '&:hover': {
        backgroundColor: useDarkMode
          ? themeColors.table.cell.odd.hover.dark
          : themeColors.table.cell.odd.hover.light,
      },
      backgroundColor: useDarkMode
        ? themeColors.table.cell.odd.dark
        : themeColors.table.cell.odd.light,
      padding: 0,
    },
  }
}

export function tableSortStyles(): { sortDirection: any } {
  return {
    sortDirection: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }
}

export function tabStyles(useDarkMode: boolean): {
  tab: {
    [key: string]: any
  }
} {
  return {
    tab: {
      '&:hover': {
        backgroundColor: useDarkMode
          ? themeColors.tab.hover.dark
          : themeColors.tab.hover.light,
      },
      fontWeight: 'bold',
    },
  }
}
