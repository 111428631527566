import firebase from 'firebase/compat/app'

import { Transaction } from 'types'
import { cleanObject } from 'utils/objects'

import { COLLECTION_TRANSACTIONS, db } from './firebase'

async function addTransaction(transaction: Transaction): Promise<void> {
  await db.collection(COLLECTION_TRANSACTIONS).add(cleanObject(transaction))
}

async function getTransactionsByUser({
  lastItem,
  limit = 100,
  transactionTypes,
  userKey,
}: {
  lastItem?: firebase.firestore.QueryDocumentSnapshot
  limit?: number
  transactionTypes?: Transaction['type'][]
  userKey: string
}): Promise<{
  data: Transaction[]
  pagination?: { lastDocument: firebase.firestore.QueryDocumentSnapshot }
}> {
  let queryBuilder = db
    .collection(COLLECTION_TRANSACTIONS)
    .where('userKey', '==', userKey)
    .orderBy('date', 'desc')

  if (transactionTypes && transactionTypes?.length > 0) {
    queryBuilder = queryBuilder.where('type', 'in', transactionTypes)
  }

  if (lastItem) {
    queryBuilder = queryBuilder.startAfter(lastItem)
  }

  const querySnapshot = await queryBuilder.limit(limit).get()
  if (querySnapshot.empty) {
    return { data: [] }
  }

  const queryDocuments = querySnapshot.docs
  const data = queryDocuments.map(
    (doc: firebase.firestore.DocumentData): Transaction => {
      const item = doc.data() as Transaction

      return {
        ...item,
        key: doc.id,
      }
    },
  )
  const lastDocument = querySnapshot.docs[queryDocuments.length - 1]

  return {
    data,
    pagination: { lastDocument },
  }
}

export const ApiServiceTransactions = {
  addTransaction,
  getTransactionsByUser,
}
