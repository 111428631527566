import { ScratchCard } from 'types'

import { cloudFunctions } from './firebase'

async function buyScratchCard({
  price,
  shopKey,
  shopName,
}: {
  price: number
  shopKey: string
  shopName: string
}): Promise<ScratchCard> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-scratchCards-buyOne',
  )

  // Call function
  const result = await cloudFunction({
    price,
    shopKey,
    shopName,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

async function redeemScratchCardPrize({
  cardKey,
  shopKey,
  shopName,
}: {
  cardKey: string
  shopKey: string
  shopName: string
}): Promise<ScratchCard> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-scratchCards-redeemPrize',
  )

  // Call function
  const result = await cloudFunction({
    cardKey,
    shopKey,
    shopName,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

export const ApiServiceGameScratchCards = {
  buyScratchCard,
  redeemScratchCardPrize,
}
