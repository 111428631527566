import firebase from 'firebase/compat/app'

import { calculateStartAndEndDateFromUnix } from 'common/date-formats'
import { WinningNumber, WinningNumberMap } from 'types'

import { COLLECTION_WINNING_NUMBERS, db } from './firebase'

async function getWinningNumbersByDate(
  date: number,
): Promise<WinningNumberMap> {
  const { endDate, startDate } = calculateStartAndEndDateFromUnix(date)
  const map: WinningNumberMap = new Map()

  const querySnapshot: firebase.firestore.QuerySnapshot = await db
    .collection(COLLECTION_WINNING_NUMBERS)
    .where('drawDate', '>=', startDate)
    .where('drawDate', '<=', endDate)
    .orderBy('drawDate', 'desc')
    .orderBy('gameTypeName', 'desc')
    .get()

  if (querySnapshot.empty) {
    return map
  }

  const queryDocuments = querySnapshot.docs
  queryDocuments.forEach((doc: firebase.firestore.DocumentData): void => {
    const item = doc.data() as WinningNumber
    const number: WinningNumber = {
      ...item,
      key: doc.id,
    }

    if (
      !number.isTestMode &&
      (number.isGameTypeActive === undefined ||
        number.isGameTypeActive === true)
    ) {
      // if (number.gameTypeCountry !== 'internal' && number.state !== 'done') {
      //   return
      // }

      if (map.has(item.gameTypeKey)) {
        const numbers = map.get(item.gameTypeKey) || []
        map.set(item.gameTypeKey, numbers.concat([number]))
      } else {
        map.set(item.gameTypeKey, [number])
      }
    }
  })

  return map
}

export const ApiServiceWinningNumbers = {
  getWinningNumbersByDate,
}
