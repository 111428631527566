import { GameConfiguration } from 'types/game-configuration'

import { COLLECTION_GAME_CONFIGURATION, db } from './firebase'

async function getGameConfiguration(
  shopKey: string,
): Promise<{ [key: string]: GameConfiguration } | null> {
  const querySnapshot = await db
    .collection(COLLECTION_GAME_CONFIGURATION)
    .doc(shopKey)
    .get({ source: 'server' })
  const item = querySnapshot.data()

  return item || null
}

export const ApiServiceGameConfigurations = {
  getGameConfiguration,
}
