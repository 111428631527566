import { Actions, State, createStore, createTypedHooks } from 'easy-peasy'
import { persistReducer, persistStore } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import Sentry from 'utils/sentry'

import { RELATED_PLATFORM_SITE } from 'config/app-version'

import { GameTypesModel, gameTypesModel } from './game-types-model'
import { SessionModel, sessionModel } from './session-model'
import { ShopsModel, shopsModel } from './shops-model'
import { TicketModel, ticketModel } from './ticket-model'
import {
  UserNotificationModel,
  userNotificationModel,
} from './user-notifications-model'
import { UserModel, userModel } from './user-model'

interface StoreModel {
  gameTypes: GameTypesModel
  session: SessionModel
  shops: ShopsModel
  ticket: TicketModel
  user: UserModel
  userNotifications: UserNotificationModel
}

const model: StoreModel = {
  gameTypes: gameTypesModel,
  session: sessionModel,
  shops: shopsModel,
  ticket: ticketModel,
  user: userModel,
  userNotifications: userNotificationModel,
}
const { useStoreActions, useStoreState } = createTypedHooks<StoreModel>()
const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production' || true

const persistConfig = {
  key: `${RELATED_PLATFORM_SITE}-${process.env.REACT_APP_ENVIRONMENT}-0.0.1`,
  storage,
  ...(isProduction && {
    stateReconciler: autoMergeLevel2,
    transforms: [
      encryptTransform({
        secretKey: process.env.REACT_APP_SECRET_KEY || '',
      }),
    ],
  }),
}

// @ts-ignore
const store = createStore(model, {
  devTools: !isProduction,
  disableImmer: true,
  reducerEnhancer: (reducer: any): any =>
    persistReducer(persistConfig, reducer),
})

/**
 * Persistor instance that persist the store into the local storage.
 */
const persistor = persistStore(store)

/**
 * Returns all stores to its initial state.
 */
export function resetStores(): void {
  try {
    Object.values(store.dispatch).forEach(
      (storeAction: any): void =>
        storeAction.resetStore && storeAction.resetStore(),
    )
  } catch (error) {
    Sentry.captureException(error)
  }
}
export type StateModel = State<StoreModel>
export type ActionModel = Actions<StoreModel>
export { persistor, useStoreActions, useStoreState }

export default store
