import firebase from 'firebase/compat/app'

import { GameCashInShare } from 'types/game-cash-in-share'

import { COLLECTION_GAME_CASH_IN_SHARES, db } from './firebase'

async function findGameCashInShares({
  lastTicket,
  limit = 30,
  userKey,
}: {
  lastTicket?: firebase.firestore.QueryDocumentSnapshot
  limit?: number
  userKey: string
}): Promise<{
  data: GameCashInShare[]
  pagination?: { lastDocument: firebase.firestore.QueryDocumentSnapshot }
}> {
  let itemsQuery: firebase.firestore.Query

  if (lastTicket) {
    itemsQuery = db
      .collection(COLLECTION_GAME_CASH_IN_SHARES)
      .where('userKey', '==', userKey)
      .orderBy('createdAt', 'desc')
      .startAfter(lastTicket)
  } else {
    itemsQuery = db
      .collection(COLLECTION_GAME_CASH_IN_SHARES)
      .where('userKey', '==', userKey)
      .orderBy('createdAt', 'desc')
  }

  const querySnapshot = await itemsQuery.limit(limit).get()
  if (querySnapshot.empty) {
    return { data: [] }
  }

  const queryDocuments = querySnapshot.docs
  const data = queryDocuments.map(
    (doc: firebase.firestore.DocumentData): GameCashInShare => {
      const item = doc.data() as GameCashInShare

      return {
        ...item,
        key: doc.id,
      }
    },
  )
  const lastDocument = querySnapshot.docs[queryDocuments.length - 1]

  return {
    data,
    pagination: { lastDocument },
  }
}

export const ApiServiceGameCashInShare = {
  findGameCashInShares,
}
