import { Action, action } from 'easy-peasy'

import { BeforeInstallPrompt, Session } from 'types'

export interface SessionModel extends Session {
  // Actions
  resetStore: Action<SessionModel, Session>
  setSession: Action<SessionModel, Session>
  setSessionAlert: Action<SessionModel, Partial<Session>>
  setDarkMode: Action<SessionModel, boolean>
  setSeenNewUserAlert: Action<SessionModel, boolean>
  setPromptHandler: Action<SessionModel, BeforeInstallPrompt>
}

const initialState: Session = {
  alertDescription: '',
  alertTitle: '',
  promptHandler: undefined,
  seenNewUserAlert: false,
  showAlert: false,
  useDarkMode: false,
}

const sessionModel: SessionModel = {
  ...initialState,
  resetStore: action((state: Session): any => {
    return { ...state, ...initialState }
  }),
  setDarkMode: action(
    (state: Session, useDarkMode = false): Session => ({
      ...state,
      useDarkMode,
    }),
  ),
  setPromptHandler: action(
    (state: Session, promptHandler: BeforeInstallPrompt): Session => ({
      ...state,
      promptHandler,
    }),
  ),
  setSeenNewUserAlert: action(
    (state: Session, seenNewUserAlert: boolean): Session => ({
      ...state,
      seenNewUserAlert,
    }),
  ),
  setSession: action(
    (state: Session, payload: Session): Session => ({ ...state, ...payload }),
  ),
  setSessionAlert: action(
    (state: Session, payload: Partial<Session>): Session => ({
      ...state,
      ...payload,
    }),
  ),
}

export { sessionModel }
