import { GameCasino } from 'types'
import { isMobile } from 'utils/detect-device'

import { cloudFunctions } from './firebase'

async function getGames(): Promise<{
  liveGames: GameCasino[]
  recentGames: GameCasino[]
  slots: GameCasino[]
  tableGames: GameCasino[]
  scratchCards: GameCasino[]
}> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable('casino-getGamesV4')

  // Call function
  const result = await cloudFunction({
    // isTestMode: '1'
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data
}

async function openDragonGamingCasinoGame({
  gameId,
  gameType,
  shopKey,
}: {
  gameId: number
  gameType: string
  shopKey: string
}): Promise<string> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'casino-dragonGaming-openGameV2',
  )

  // Call function
  const result: {
    data: {
      url: string
      error?: string
    }
  } = await cloudFunction({
    d: '0',
    gameId,
    gameType,
    platform: isMobile() ? 'mobile' : 'desktop',
    shopKey,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data.url
}

async function openDotSlotsCasinoGame(
  userId: string,
  gameCode: string,
  shopKey: string,
): Promise<string> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable('casino-dotSlots-openGame')

  const result: {
    data: {
      url: string
      error?: string
    }
  } = await cloudFunction({
    gameCode,
    shopKey,
    userId,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data.url
}

async function openGamingPlatformsCasinoGame(
  userId: string,
  gameCode: string,
  shopKey: string,
): Promise<{ url?: string; html?: string }> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'casino-gamingPlatforms-v1-openGame',
  )

  const result: {
    data: {
      url?: string
      html?: string
      error?: string
    }
  } = await cloudFunction({
    d: '0',
    gameCode,
    platform: isMobile() ? 'mobile' : 'desktop',
    shopKey,
    userId,
  })

  if (result?.data?.error) {
    throw new Error(result.data.error)
  }

  return { html: result.data.html, url: result.data.url }
}

export const ApiServiceCasinoGames = {
  getGames,
  openDotSlotsCasinoGame,
  openDragonGamingCasinoGame,
  openGamingPlatformsCasinoGame,
}
