import { cloudFunctions } from './firebase'

async function getCurrentTime(): Promise<number> {
  // Get cloud function
  const getTimeFunction = cloudFunctions.httpsCallable('global-getCurrentTime')

  // Call function
  const result = await getTimeFunction()

  if (!result || !result.data) {
    throw new Error('Invalid time')
  }

  // Return admin object
  return result.data
}

export const ApiServiceGlobal = {
  getCurrentTime,
}
