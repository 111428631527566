import firebase from 'firebase/compat/app'
import { digest } from 'object-sha'
import moment from 'moment-timezone'

import {
  Ticket,
  TicketBet,
  TicketBetMegaReventados,
  TicketBetReventados,
} from 'types'

import { COLLECTION_GAME_TICKETS, cloudFunctions, db } from './firebase'

async function createTicket({
  gameDate,
  gameTypeKey,
  shopKey,
  ticketBets,
  ticketBetsMegaReventados,
  ticketBetsReventados,
  timeSlot,
  userId,
}: {
  gameDate: number
  gameTypeKey: string
  shopKey: string
  ticketBets?: TicketBet
  ticketBetsMegaReventados?: TicketBetMegaReventados
  ticketBetsReventados?: TicketBetReventados
  timeSlot: string
  userId: string
}): Promise<Ticket> {
  // Get cloud function
  const cloudFunction = cloudFunctions.httpsCallable(
    'client-tickets-createTicketV2',
  )

  const ticketData = {
    gameDate,
    gameTypeKey,
    requestTimestamp: moment().unix(),
    shopKey,
    ...(Object.keys(ticketBets || {}).length && { ticketBets }),
    ...(Object.keys(ticketBetsReventados || {}).length && {
      ticketBetsReventados,
    }),
    ...(Object.keys(ticketBetsMegaReventados || {}).length && {
      ticketBetsMegaReventados,
    }),
    timeSlot,
  }
  const ticketSha = await digest({ ...ticketData, userId })

  // Call function
  const result = await cloudFunction({ ...ticketData, ticketSha })

  if (result.data?.error) {
    throw new Error(result.data.error)
  }

  return result.data.ticket
}

async function getTicketsByUser({
  lastTicket,
  limit = 30,
  userKey,
}: {
  lastTicket?: firebase.firestore.QueryDocumentSnapshot
  limit?: number
  userKey: string
}): Promise<{
  data: Ticket[]
  pagination?: { lastDocument: firebase.firestore.QueryDocumentSnapshot }
}> {
  let itemsQuery: firebase.firestore.Query

  if (lastTicket) {
    itemsQuery = db
      .collection(COLLECTION_GAME_TICKETS)
      .where('userKey', '==', userKey)
      .orderBy('datePlayed', 'desc')
      .startAfter(lastTicket)
  } else {
    itemsQuery = db
      .collection(COLLECTION_GAME_TICKETS)
      .where('userKey', '==', userKey)
      .orderBy('datePlayed', 'desc')
  }

  const querySnapshot = await itemsQuery.limit(limit).get()
  if (querySnapshot.empty) {
    return { data: [] }
  }

  const queryDocuments = querySnapshot.docs
  const data = queryDocuments.map(
    (doc: firebase.firestore.DocumentData): Ticket => {
      const item = doc.data() as Ticket

      return {
        ...item,
        key: doc.id,
      }
    },
  )
  const lastDocument = querySnapshot.docs[queryDocuments.length - 1]

  return {
    data,
    pagination: { lastDocument },
  }
}

export const ApiServiceTickets = {
  createTicket,
  getTicketsByUser,
}
